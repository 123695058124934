import { useIntl } from "react-intl";

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

export const parseNumberStringToArray = (numberString) => {
  const regex = /\[(\d+)\]/g;
  const matches = [];
  let match;

  while ((match = regex.exec(numberString)) !== null) {
    const number = parseInt(match[1], 10);
    matches.push(number);
  }

  return matches;
};

export function TimeDiffToDisplay(diff) {
  const seconds = Math.floor(Math.abs(diff) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const intl = useIntl();

  if (days > 0) {
    return `${days} ${intl.formatMessage({ id: "day" })}${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} ${intl.formatMessage({ id: "hour" })}${
      hours > 1 ? "s" : ""
    }`;
  } else if (minutes > 0) {
    return `${minutes} ${intl.formatMessage({ id: "minute" })}${
      minutes > 1 ? "s" : ""
    }`;
  } else {
    return `${seconds} ${intl.formatMessage({ id: "second" })}${
      seconds > 1 ? "s" : ""
    }`;
  }
}

export const getInvoiceStart = (date) => {
  let formatted = date.replace("-", "");
  formatted = formatted.slice(0, 6);
  return formatted;
};

export function getBrowserLocale() {
  switch (navigator.language) {
    case "zh":
    case "zh-Hant":
    case "zh-Hans":
    case "zh-TW":
    case "zh-HK":
    case "zh-CN":
    case "en-GB":
    case "en-US":
    case "en":
      return "en-GB";
    default:
      return "lv-LV";
  }
}
