import { Button, Popover, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import {
  CUSTOMgetClientAPIstatus,
  CUSTOMsendClientAPIstatus,
} from "../functions/api";

export const GetCustomElement = ({ style, custom, args }) => {
  if (custom == "lmrf-send") {
    if (args[0] && args[0].column_value && args[0].column_value.length > 0) {
      return <LmrfSend data={args[0]} style={style} />;
    } else {
      return (
        <Button disabled style={style}>
          Nav Kadastra numurs
        </Button>
      );
    }
  }
};

export const LmrfSend = ({ data, style }) => {
  const [status, setStatus] = useState([]);
  const [refresh, setRefresh] = useState();

  const getStatus = () => {
    if (data && data?.clientid) {
      console.log("receiving, for:", data?.clientid);
      CUSTOMgetClientAPIstatus({ clientid: data.clientid }).then((x) => {
        if (x?.length > 0 && x[0].raw != "submitted") {
          console.log("should parse>", x[0]);
          const decoded = JSON.parse(x[0]?.raw);
          const y = { ...x[0], ...decoded };
          setStatus([y]);
        } else {
          setStatus(x);
        }
      });
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const submit = () => {
    if (data?.clientid) {
      console.log("sending", data);
      CUSTOMsendClientAPIstatus({
        clientid: data.clientid,
        column_name: data.column_name,
        column_value: data.column_value,
      }).then((x) => getStatus());
    }
  };

  return status?.length == 0 ? (
    <Button
      onClick={submit}
      style={{ ...style, ...{ backgroundColor: "green" } }}
    >
      Sūtīt kadastru novērtējumam
    </Button>
  ) : status[0].raw == "submitted" ? (
    <Button
      style={{ ...style, ...{ backgroundColor: "grey", cursor: "default" } }}
    >
      Nosūtīts novērtējumam
    </Button>
  ) : (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button
          style={{
            ...style,
            ...{ backgroundColor: "limegreen" },
          }}
        >
          Novērtējums saņemts
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm">
          <strong>Vērtība:</strong> {status[0].value}
          <br />
          <strong>Komentārs:</strong> {status[0].comment}
          <br />
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};
